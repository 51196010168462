import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { Contrast, useContrast } from '@entur/layout';
import { LeadParagraph, Paragraph } from '@entur/typography';
import { Playground } from '~/components/playground/Playground';
import { ImportStatement } from '~/components/ImportStatement';
import PageHeader from '~/components/PageHeader';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<div style={{ padding: \'4em\' }}>\n  <Paragraph>\n    Det fine med kontrastseksjoner er at man ikke trenger å tenke på hvordan\n    de skal brukes. Du kan bare plassere dem rundt litt kode, og komponentene\n    inni vil endre utseendet basert på CSS-variabler.\n  </Paragraph>\n</div>\n<Contrast>\n  <div style={{ padding: \'4em\' }}>\n    <Paragraph>\n      Det fine med kontrastseksjoner er at man ikke trenger å tenke på hvordan\n      de skal brukes. Du kan bare plassere dem rundt litt kode, og\n      komponentene inni vil endre utseendet basert på CSS-variabler.\n    </Paragraph>\n  </div>\n</Contrast>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Contrast,
      useContrast,
      LeadParagraph,
      Paragraph,
      Playground,
      ImportStatement,
      PageHeader,
      DefaultLayout,
      _frontmatter
    }} hideContrastOption={true} mdxType="Playground">
  <div style={{
        padding: '4em'
      }}>
    <Paragraph mdxType="Paragraph">
      Det fine med kontrastseksjoner er at man ikke trenger å tenke på hvordan
      de skal brukes. Du kan bare plassere dem rundt litt kode, og komponentene
      inni vil endre utseendet basert på CSS-variabler.
    </Paragraph>
  </div>
  <Contrast mdxType="Contrast">
    <div style={{
          padding: '4em'
        }}>
      <Paragraph mdxType="Paragraph">
        Det fine med kontrastseksjoner er at man ikke trenger å tenke på hvordan
        de skal brukes. Du kan bare plassere dem rundt litt kode, og
        komponentene inni vil endre utseendet basert på CSS-variabler.
      </Paragraph>
    </div>
  </Contrast>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "contrast"
    }}>{`Contrast`}</h3>
    <ImportStatement imports="Contrast" mdxType="ImportStatement" />
    <Props of={Contrast} mdxType="Props" />
    <h3 {...{
      "id": "usecontrast"
    }}>{`UseContrast`}</h3>
    <ImportStatement imports="useContrast" mdxType="ImportStatement" />
    <Props of={useContrast} mdxType="Props" />
    <p>{`Returnerer `}<inlineCode parentName="p">{`true`}</inlineCode>{` om man er wrappet av en `}<inlineCode parentName="p">{`Contrast`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Contrast er en invertert versjon av profilen vår og kan brukes for å skape et visuelt hierarki på siden.
Forskjeller tiltrekker blikket og Contrast gir oss muligheten å presentere et oppsett på en måte som informerer
brukene om hvilke handlinger og interaksjoner som er viktige, og hvilke som er sekundære.`}</p>
    <p>{`Contrast er en effektiv måte å gi fokus på bestemte elementer i designet og for å fange brukernes oppmerksomhet.
Riktig bruk av Contrast på ulike seksjoner kan være et effektfullt grep som kan bidra til intuitiv navigering og
mer brukervennlig side. I tillegg kan Contrast seksjoner redusere mengden lys, som gjør det lettere for øynene å se på skjermen.
Contrast kan også forbedre lesbarheten på avstand og ved ulike lysforhold.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      